import topic from '../topic';
import { getNoCatch } from '@api/request';
export const ConsultAPI = {
  getConsult: topic.consultTopic + '/get_consult',
  deleteConsultById: topic.consultTopic + '/delete_consult_by_id',
  getConsultById: topic.consultTopic + '/get_consult_by_id',
  editConsult: topic.consultTopic + '/edit_consult',
  updatetablePricestatus: topic.consultTopic + '/update_table_price_status',
  informDyjRepair: topic.consultTopic + '/informDyjRepair',
  subToBoss: topic.consultTopic + '/subToBoss',
  exportPartList: params => getNoCatch(`${topic.consultTopic}/exportPartList`, params)
};
