<template>
  <el-card>
    <div class="vg_mb_8">
      <!--      <el-button type="danger" size="small" @click="doDelete()" icon="el-icon-delete">删除</el-button>-->
      <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新 </el-button>
    </div>
    <SearchTable
      ref="consultMultiTable"
      :data="tableData"
      :tableRowKey="'consult_id'"
      :columns="tableProperties"
      v-loading="orderLoadFlag"
      :totalPage="totalPage"
      @selection-change="selectionChange"
      @row-dblclick="dbClickJp"
      @getTableData="() => getConsults()"
    >
      <template v-slot:status="scope">
        <div style="text-align: center">
          <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
            {{ helper.getStatusName(scope.row.status).name }}
          </el-tag>
        </div>
      </template>
    </SearchTable>
  </el-card>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import helper from '@assets/js/helper.js';
import { deptAPI } from '@api/modules/department';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { ConsultAPI } from '@api/modules/consult';
import { getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ConsultList',
  computed: {
    helper() {
      return helper;
    }
  },
  components: {
    SearchTable
  },
  data() {
    return {
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
        { prop: 'consult_no', itemType: 'input', input: false, label: '咨询单编号', sortable: false, widthAuto: true },
        { prop: 'cust_abbr', itemType: 'input', label: '客户简称', sortable: false, widthAuto: true },
        { prop: 'series_name', itemType: 'input', label: '系列名称', sortable: false, widthAuto: true },
        { prop: 'cust_order_no', itemType: 'input', label: '客户订单号', sortable: false, widthAuto: true },
        { prop: 'scon_dept_name', itemType: 'input', label: '销售部门', sortable: false, widthAuto: true },
        { prop: 'price_name', label: '核价人', itemType: 'input', sortable: false, widthAuto: true },
        {
          prop: 'table_price_status',
          itemType: 'select',
          label: '报价状态',
          options: [
            { value: 0, label: '未报价' },
            { value: 1, label: '报价中' },
            { value: 2, label: '已报价' }
          ],
          needOtherColumn: true,
          formatter: val => this.formatPriceStatus(val),
          sortable: false,
          widthAuto: true
        },
        {
          prop: 'create_time',
          label: '录入日期',
          itemType: 'date',
          input: false,
          sortable: true,
          formatter: val => getDateNoTime(val, false),
          widthAuto: true
        },
        {
          prop: 'consult_remark',
          label: '外销备注',
          itemType: 'input',
          input: false,
          sortable: false,
          widthAuto: true
        },
        {
          prop: 'status',
          label: '单据状态',
          itemType: 'select',
          formatter: val => (val === 0 ? '草拟' : val === 1 ? '在批' : val === 2 ? '生效' : '暂无'),
          options: [
            { value: 0, label: '草拟' },
            { value: 1, label: '在批' },
            { value: 2, label: '生效' }
          ],
          widthAuto: true
        }
      ],
      btn: {},
      tabIndex: 1,
      activeName: 'consult',
      loadFlag: true,
      currentPage: 1,
      consultLoadFlag: false,
      totalPage: 0,
      tableData: [],
      consultSelections: [],
      orderLoadFlag: false
    };
  },
  mounted() {
    if (this.$route.query.cust_order_no) this.$refs.consultMultiTable.searchForm = this.$route.query.cust_order_no;
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getConsults();
    },
    // 获取订单咨询单信息
    getConsults() {
      let searchForm = this.$refs.consultMultiTable.searchForm;
      get(ConsultAPI.getConsult, searchForm).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          this.loadFlag = true;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message.error({ message: mg, type: tp });
        }
      });
    },

    //获取部门信息
    getDept() {
      getNoCatch(deptAPI.getAllDeptsV1).then(({ data }) => {
        let arr = Array.from(data, ({ dept_name, dept_id }) => {
          return { value: dept_id, label: dept_name };
        });
        this.tableProperties[12].options = arr;
        this.tableProperties[14].options = arr;
      });
    },
    //获取客户信息
    getCust() {
      getNoCatch(custAPI.getAllCustsV1).then(({ data }) => {
        this.custList = data.list;
      });
    },
    // 查询方法
    getPodrsNow() {
      this.loadFlag = true;
      this.getConsults();
    },
    // 刷新
    buttonRefresh() {
      this.loadFlag = true;
      this.$refs.consultMultiTable.resetFields();
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getConsults();
    },
    //升序 降序
    sortChange({ order, prop }) {
      if (!order) {
        this.searchForm.column = '';
        this.searchForm.order = '';
        return;
      }
      this.searchForm.column = prop;
      this.searchForm.order = order.substring(0, order.indexOf('ending'));
    },
    // 核价状态返回值
    formatPriceStatus(val) {
      if (val.table_price_status === 0) {
        return '未报价';
      } else if (val.table_price_status === 1) {
        return '报价中';
      } else {
        return '已报价';
      }
    },
    selectionChange(val) {
      this.consultSelections = val;
    },
    //删除
    doDelete() {
      this.$confirm('是否删除该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = '';
        console.log(this.consultSelections);
        ids = getArrayIds(this.consultSelections, 'consult_id').join(',');
        getNoCatch(ConsultAPI.deleteConsultById, { consult_ids: ids }).then(() => {
          this.$message.success('删除成功!');
          this.getConsults();
        });
      });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/consult_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.consult_id
          })
        )
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
